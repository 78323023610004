import React from 'react';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faMeetup } from '@fortawesome/free-brands-svg-icons';
import { faXing } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

class LinksSidebar extends React.Component {
    render() {
        const author = this.props.data;
        const links = {
            twitter: author.twitter,
            facebook: author.facebook,
            email: author.email,
            xing: author.xing,
            meetup: author.meetup,
            instagram: author.instagram
        };

        return (
            <div className="links-sidebar">
                <ul className="links-sidebar__list">
                    <li className="links-sidebar__list-item">
                        <a href={`https://www.twitter.com/${links.twitter}`} target="_blank">
                            <FontAwesomeIcon icon={faTwitter}/>
                        </a>
                    </li>
                    <li className="links-sidebar__list-item">
                        <a href={`https://www.meetup.com/de-DE/${links.meetup}`} target="_blank">
                    <FontAwesomeIcon icon={faMeetup}/>
                        </a>
                    </li>
                    <li className="links-sidebar__list-item">
                        <a href={`https://www.xing.com/companies/${links.xing}`} target="_blank">
                            <FontAwesomeIcon icon={faXing}/>
                        </a>
                    </li>
                    <li className="links-sidebar__list-item">
                        <a href={`https://de-de.facebook.com/${links.facebook}`} target="_blank">
                            <FontAwesomeIcon icon={faFacebook}/>
                        </a>
                    </li>
                    <li className="links-sidebar__list-item">
                        <a href={`https://www.instagram.com/${links.instagram}`} target="_blank">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default LinksSidebar;
